import DraggablePopup from '@/components/popups/DraggablePopup';
import { Box, Button, Stack, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { AccessEnum, RolesEnum } from '@/app/auth/roles';
import { useHasAnyRoleSet } from '@/hooks/useAccess';
import { useComplaintStore } from '../store';
import { AccessController } from '@/components/common/AccessController';
import ComplaintFormEnergy from '../ComplaintFormEnergy/ComplaintFormEnergy';
import ComplaintFormODPU from '../ComplaintFormODPU/ComplaintFormODPU';
import { MainText } from './ComplaintAddButton.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

const ComplaintAddButton = () => {
  const { handleSearch, setSelectedCompliant } = useComplaintStore((state) => ({
    handleSearch: state.handleSearch,
    setSelectedCompliant: state.setSelectedCompliant,
  }));
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const createEnergy = useHasAnyRoleSet([
    [RolesEnum.CallCenterSec],
    [RolesEnum.ComplaintCustomer, RolesEnum.ComplaintEnergo],
    [RolesEnum.ComplaintManager, RolesEnum.ComplaintEnergo],
  ]);


  const createOdpu = useHasAnyRoleSet([
    [RolesEnum.ComplaintCustomer, RolesEnum.ComplaintOdpu],
    [RolesEnum.ComplaintManager, RolesEnum.ComplaintOdpu],
  ]);

  

  const closeModal = () => setIsOpenModal(false);
  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (!createEnergy) setActiveTab(1);
  }, [createEnergy]);

  const hideAddButton = !createOdpu && !createEnergy;
  const isMobile = useMobileSize();

  return (
    <>
      {!hideAddButton && (
        <Button
          onClick={() => {
            setSelectedCompliant(null);
            setIsOpenModal(true);
          }}
        >
          + Добавить
        </Button>
      )}

      <DraggablePopup
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        customStyle={{ minHeight: 600 }}
      >
        <Box>
          <Stack>
            <MainText>Для создания жалобы внесите необходимые данные</MainText>
          </Stack>
          <Tabs
            orientation={isMobile ? 'vertical' : 'horizontal'}
            value={activeTab}
            onChange={handleTabChange}
            centered
          >
            {createEnergy && <Tab label='ГАРАНТИЙНЫЕ ОБЯЗАТЕЛЬСТВА' />}

            {createOdpu && (
              <Tab
                label='ОДПУ'
                disabled={!createEnergy}
              />
            )}
          </Tabs>
          <Box>
            {activeTab === 0 && (
              <>
                <AccessController access={[AccessEnum.ComplaintsCreateEnergyType]}>
                  <ComplaintFormEnergy closeModal={closeModal} />
                </AccessController>
              </>
            )}
            {activeTab === 1 && (
              <AccessController access={[AccessEnum.ComplaintsCreateOdpuType]}>
                <ComplaintFormODPU
                  closeModal={closeModal}
                  handleSearch={handleSearch}
                />
              </AccessController>
            )}
          </Box>
        </Box>
      </DraggablePopup>
    </>
  );
};

export default ComplaintAddButton;
